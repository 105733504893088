import Logo from "../assets/Group 1.png";
import Prof from "../assets/Vector (1).png";
import { Link } from "react-router-dom";

export default function Forgetpassword() {

  return (
    <div
      className="h-screen"
      style={{
        background: `linear-gradient(
  to top right,
  #d3c3df 0%,
  white 50%,
  #bbdaed 100%)`,
      }}
    >
      <div className="flex flex-col justify-center items-center w-[500px] mx-auto gap-y-5 pt-[8%] font-sans">
        <div className="w-[220px]">
          <img className="w-100 h-100" src={Logo} alt="Logo" />
        </div>
        <h1 className="text-xl font-semibold">
          Please enter your email on your Account{" "}
        </h1>
        <div className="bg-[#FEE4E4] py-4 px-5 w-[380px] rounded-xl">
          <p className="text-[#FF2B37] text-center">Enter a valid Email</p>
        </div>
        <form className="flex flex-col justify-center gap-y-3">
          <div className="mb-4 w-[380px]">
            <label className="block text-gray-700 mb-2 " htmlFor="email" >
              Enter Email
            </label>
            <div className="flex flex-row gap-x-2 p-3 rounded-lg border">
              <div className="px-3 border-r">
                <img src={Prof} alt="Profile Icon" />
              </div>
              <input
                type="email"
                id="email"
                className="border-0 text-[16px] focus:outline-none w-[300px] bg-transparent"
                placeholder="Enter Email"
              />
            </div>
          </div>

          <Link
            to="/emailsent"
            className="bg-[#662D91] text-white text-center text-[16px] p-3 rounded-xl hover:bg-white hover:text-custom-purple hover:border-custom-purple hover:border"
          >
            Reset Password
          </Link>
        </form>
      </div>
    </div>
  );
}
