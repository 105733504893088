import Logo from "../assets/Group 1.png";
import { Link } from "react-router-dom";

export default function PasswordSuccess() {
  return (
    <div
      className="h-screen"
      style={{
        background: `linear-gradient(
  to top right,
  #d3c3df 0%,
  white 50%,
  #bbdaed 100%)`,
      }}
    >
      <div className="flex flex-col justify-center items-center w-[500px] mx-auto gap-y-5 pt-[10%] font-sans">
        <div className="w-[220px]">
          <img className="w-100 h-100" src={Logo} alt="Logo" />
        </div>

        <div className="flex flex-col justify-center gap-y-7 mt-[150px]">
          <h1 className="text-xl font-semibold text-center">
            Password was successfully Reset{" "}
          </h1>

          <Link
            to="/login"
            className="bg-[#662D91] text-center mx-auto text-white w-[370px] text-[16px] p-3 rounded-xl hover:bg-white hover:text-custom-purple hover:border-custom-purple hover:border"
          >
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
}
