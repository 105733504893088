import React, { useState } from "react";
import Logo from "../assets/Group 1.png";
import eye from "../assets/Group (1).png";
import eyeSlash from "../assets/Group (1).png"; // Import an eye-slash icon for toggling
import Lock from "../assets/Vector (2).png";
import { Link } from "react-router-dom";

export default function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className="h-screen"
      style={{
        background: `linear-gradient(
  to top right,
  #d3c3df 0%,
  white 50%,
  #bbdaed 100%)`,
      }}
    >
      <div className="flex flex-col justify-center items-center w-[400px] mx-auto gap-y-5 pt-[8%] font-sans">
        <div className="w-[220px]">
          <img className="w-100 h-100" src={Logo} alt="Logo" />
        </div>
        <div className="bg-[#FEE4E4] py-4 px-5 w-[380px] rounded-xl">
          <p className="text-[#FF2B37] text-center">Password Don't Match</p>
        </div>
        <form className="flex flex-col justify-center gap-y-3">
          <div className="mb-4 w-[380px]">
            <label className="block text-gray-700 mb-2" htmlFor="password">
              New Password
            </label>
            <div className="flex flex-row gap-x-2 p-3 rounded-lg border items-center">
              <div className="px-3 border-r">
                <img src={Lock} alt="Lock Icon" />
              </div>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                className="border-0 text-[16px] focus:outline-none w-[280px] bg-transparent"
                placeholder="Enter New Password"
              />
              <div
                className=" cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                <img
                  src={showPassword ? eyeSlash : eye}
                  alt="Toggle Password Visibility"
                />
              </div>
            </div>
          </div>
          <div className="mb-4 w-[380px]">
            <label className="block text-gray-700 mb-2" htmlFor="password">
              Confirm Password
            </label>
            <div className="flex flex-row gap-x-2 p-3 rounded-lg border items-center ">
              <div className="px-3 border-r">
                <img src={Lock} alt="Lock Icon" />
              </div>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                className="border-0 text-[16px] focus:outline-none w-[280px] bg-transparent"
                placeholder="Enter Confirm Password"
              />
              <div
                className=" cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                <img
                  src={showPassword ? eyeSlash : eye}
                  alt="Toggle Password Visibility"
                />
              </div>
            </div>
          </div>

          <Link
            to="/passwordsuccess"
            className="bg-[#662D91] text-center text-white text-[16px] p-3 rounded-xl hover:bg-white hover:text-custom-purple hover:border-custom-purple hover:border"
          >
            Reset Password
          </Link>
        </form>
      </div>
    </div>
  );
}
