import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import Home from "./components/Home";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Emailsent from "./components/Emailsent";
import PasswordSuccess from "./components/Passwordsuccess";
import ResetPassword from "./components/Resetpassword";


function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/emailsent" element={<Emailsent />} />
        <Route path="/passwordsuccess" element={<PasswordSuccess />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        
      </Routes>
    </Router>
  );
}

export default App;
